import logo from './logo.svg';
import './App.css';
import MealCountForm from './MealCountForm'; // Assuming you've created a separate file for the form

function App() {
  return (
    <div className="App">
    <header className="App-header">
    <h1 >Weekly Meal Count Form - Summer Food Service Program (SFSP)</h1>
      
    </header>
    <MealCountForm />
  </div>
  );
}

export default App;
